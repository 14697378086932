import {SectionBanner} from './cmponents/sectionBanner/SectionBanner'
import {SectionAbout} from './cmponents/sectionAbout/SectionAbout'
import {SectionRoles} from './cmponents/sectionRoles/SectionRoles'
import {SectionTestimonials} from './cmponents/sectionTestimonials/SectionTestimonials'
import {SectionBlog} from './cmponents/sectionBlog/SectionBlog'
import {SectionContacts} from './cmponents/sectionContacts/SectionContacts'
import {Footer} from './cmponents/footer/Footer'
import './App.css'
import {isBrowser} from "react-device-detect";
import {FooterMob} from "./cmponents/footerMob/FooterMob";

function App() {
  return (
    <div className="App">
        <SectionBanner />
        <SectionAbout />
        <SectionRoles />
        <SectionContacts />
        {isBrowser
            ? <Footer />
            : <FooterMob />
        }
    </div>
  );
}

export default App;
