import {isBrowser, isMobileOnly} from 'react-device-detect'
import laptopIcon from '../../assets/icons/roles_laptop.svg'
import moleculeIcon from '../../assets/icons/roles_molecule.svg'
import peopleIcon from '../../assets/icons/roles_people.svg'
import loupeIcon from '../../assets/icons/roles_loupe.svg'
import styles from './sectionRoles.module.css'

export const SectionRoles = () => {
    return (
        <div id="roles" className={styles.container}>
            <div className={styles.wrapper}>
                <h2 className={`${styles.title} ${isMobileOnly && styles.titleMob}`}>My Roles</h2>

                <div className={`${styles.mainContent} ${!isBrowser && styles.mainContentMob}`}>
                    <div className={`${styles.col} ${!isBrowser && styles.colMob}`}>
                        <div className={styles.item}>
                            <img className={styles.icon} src={laptopIcon} alt="laptop icon"/>
                            <p className={styles.text}>
                                My passion for technology and innovation led me to launch my first company a decade ago, an organization dedicated to providing cutting-edge IT solutions to businesses, particularly in the demanding fintech, banking, Forex, and iGaming sectors.
                            </p>
                            <p>
                                A decade later, these companies continue to push the boundaries of IT, offering clients the robust and secure infrastructure they need to minimize disruptions, maximize growth, and thrive in today's complex digital landscape.
                            </p>
                        </div>

                        <div className={styles.item}>
                            <img className={styles.icon} src={moleculeIcon} alt="laptop icon"/>
                            <p className={styles.text}>
                                Building upon my success in IT and recognizing the evolving needs of my clients, I expanded my ventures into web and software development and digital marketing.
                            </p>
                            <p>
                                I assembled a team of like-minded creators and innovators dedicated to turning our clients' digital aspirations into reality.
                            </p>
                        </div>
                    </div>

                    <div className={`${styles.col} ${!isBrowser && styles.colMob} ${isBrowser ? styles.shifted : styles.shiftedMob}`}>
                    <div className={styles.item}>
                        <img className={styles.icon} src={peopleIcon} alt="laptop icon"/>
                        <p>
                            With the increasing digitalization of business, my focus has significantly shifted towards cybersecurity.  The rise of cryptocurrency and advancements in AI make this a critical component for all businesses.
                        </p>
                        <p className={styles.text}>
                            I'm committed to staying at the forefront of these evolving trends, ensuring robust cybersecurity solutions for my clients.
                        </p>
                    </div>

                    <div className={styles.item}>
                        <img className={styles.icon} src={loupeIcon} alt="laptop icon"/>
                        <p className={styles.text}>
                            Recruitment is also being transformed by technological advancements.  AI-powered assessments and candidate screening have revolutionized hiring.
                        </p>
                        <p>                        
                            My expertise in the latest technologies allows my agency to quickly and efficiently identify top candidates for tech roles, matching them perfectly with our clients' needs.
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}