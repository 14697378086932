import logo from '../../logo.svg'
import mailIcon from '../../assets/icons/mail_icon.svg'
import styles from './header.module.css'

export const Header = () => {
    return (
        <header>
            <div className={styles.container}>
                <a className={styles.logoWrapper} href="/">
                    <img className={styles.logoImg} src={logo} alt=""/>
                </a>

                <ul className={styles.navBar}>
                    <li className={styles.navItem}>
                        <a href="#about" className={styles.navLink}>about</a>
                    </li>
                    <li className={styles.navItem}>
                        <a href="#roles" className={styles.navLink}>roles</a>
                    </li>                    
                    <li className={styles.navItem}>
                        <a href="#contacts" className={styles.navLink}>contacts</a>
                    </li>
                </ul>

                <a className={styles.btn} href="mailto:minos@pitsilledes.com">
                    <img className={styles.btnImg} src={mailIcon} alt="mail icon"/>
                    minos@pitsilledes.com
                </a>
            </div>
        </header>
    )
}