import {useEffect, useState} from 'react'
import logo from '../../logo.svg'
import styles from './mobileHeader.module.css'

export const MobileHeader = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen])

    return (
        <header>
            <div className={`${styles.container} ${isOpen && styles.colored}`}>
                <img src={logo} alt="logo" className={styles.logo}/>

                <div
                    className={styles.burger}
                    onClick={() => setIsOpen(prevState => !prevState)}
                >
                    <span className={isOpen ? styles.lineOpen : styles.line}></span>
                    <span className={isOpen ? styles.lineOpen : styles.line}></span>
                    <span className={isOpen ? styles.lineOpen : styles.line}></span>
                </div>
            </div>

            {isOpen && (
                <nav className={styles.menu}>
                    <ul className={styles.navBar}>
                        <li className={styles.navItem} onClick={() => setIsOpen(false)}>
                            <a href="#about" className={styles.navLink}>about</a>
                        </li>
                        <li className={styles.navItem} onClick={() => setIsOpen(false)}>
                            <a href="#roles" className={styles.navLink}>roles</a>
                        </li>                        
                        <li className={styles.navItem} onClick={() => setIsOpen(false)}>
                            <a href="#contacts" className={styles.navLink}>contacts</a>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    )
}
