import {isBrowser, isMobile, isMobileOnly, isTablet} from 'react-device-detect'
import { Header } from '../header/Header'
import { MobileHeader } from '../mobileHeader/MobileHeader'
import { SocialNavBar } from '../socialNavBar/SocialNavBar'
import styles from './banner.module.css'

export const SectionBanner = () => {
    return (
        <div className={styles.container}>
            {isBrowser
                ? <Header />
                : <MobileHeader />
            }
            <div className={`${styles.wrapper} ${isMobileOnly ? styles.wrapperMobS : isTablet && styles.wrapperMob}`}>
                <h1 className={`${styles.title} ${isMobileOnly && styles.titleMob}`}>
                    Minos Pitsillides: Tech <span className={styles.titleSpan}>Enthusiast</span> & Innovator
                </h1>

                <div className={`${styles.contactBtns} ${isMobileOnly && styles.contactBtnsMob}`}>
                    <button className={styles.btnIron}>
                        <a href="https://scheduler.zoom.us/minos-pitsillides/hello-i-am-an-event-card">Schedule a call</a>
                    </button>
                    <button className={styles.btnTransparent}>
                        <a href="mailto:minos@pitsilledes.com">Send a Email</a>
                    </button>
                </div>

                <SocialNavBar style={isMobileOnly ? {bottom:"15px"} : {bottom:0}} />
            </div>
        </div>
    )
}