import {isBrowser, isMobileOnly} from 'react-device-detect'
import styles from './sectionAbout.module.css'

export const SectionAbout = () => {
    return (
        <div id="about" className={styles.container}>
            <div className={`${styles.wrapper} ${isMobileOnly && styles.wrapeerMob}`}>

                <p className={`${styles.text} ${!isBrowser && styles.textMob}`}>
                    I'm Minos, a tech entrepreneur driven by innovation.  My passion for technology has taken me from exploring gadgets as a kid to leading IT projects today. Since graduating with a Computing degree in 2009, I've built my expertise in IT consulting, infrastructure, and digital strategy, working with top law firms and international banks.  With Cisco and Microsoft certifications, I'm dedicated to providing excellent networking and security solutions. For the last ten years, I've founded and run several companies that deliver cutting-edge IT services to the banking, Forex, and iGaming industries.  My companies focus on innovative solutions for IT, Digital Marketing, Cybersecurity, and Recruitment.                </p>
                <p className={`${styles.text} ${styles.textDark} ${!isBrowser && styles.textMob}`}>
                    I saw that the fintech industry needed strong IT infrastructure, especially with growing regulations and security concerns.  So, I created specialized services for businesses of all sizes.  My team and I design and build ideal IT networks, from small offices to complex cloud systems. We also focus heavily on cybersecurity, providing complete solutions that include finding weaknesses in systems, creating custom security plans, actively responding to threats, and preventing data loss.  We also develop custom software like online trading platforms, CRM systems, hosting services, and phone systems to help our clients stay ahead.
                </p>
                <p className={`${styles.text} ${!isBrowser && styles.textMob}`}>
                    My goal is to build IT systems that will last and help businesses succeed and stay safe in today's competitive and risky digital world.  I know I've done well when my clients grow and thrive.  I'm excited to take on new challenges in technology and innovation.
                    <br ></br>Let's connect and explore the endless possibilities that technology offers.
                </p>
                <button className={`${styles.btn} ${isMobileOnly && styles.btnMob}`}><a href="https://scheduler.zoom.us/minos-pitsillides/hello-i-am-an-event-card">Get in touch</a></button>

                <div className={isMobileOnly ? styles.signMob : styles.sign}>
                    <p className={styles.signTitle}>Entrepreneur</p>
                    <p className={styles.name}>Minos Pitsillides</p>
                </div>
            </div>
        </div>
    )
}